/* General ------------------------------------*/
.pagesGrid {
	background-color: var(--wt);
	height: auto;
	padding: 3rem 10% 5rem 5rem;
	display: grid;
	grid-template-rows: repeat(3, auto);
	justify-content: flex-end;
	align-content: flex-start;
	overflow: hidden;
}

/* About  -------------------------------------*/
.about {
	background-color: var(--wt);
	height: auto;
	overflow-y: scroll;
}

.jeuelMobile {
	display: none;
}

/* Skills -----------------------------------*/
.backgroundSkills {
	background-color: var(--card);
	padding: 1rem 5rem 5rem;
}

.skillsTitle {
	text-align: center;
	margin-top: 8rem;
}

.skillsGrid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
	grid-gap: 5rem;
	justify-items: center;
}

.cardsSkills {
	width: 20rem;
	height: 25rem;
	padding: 2rem;
	display: grid;
	justify-items: center;
	align-items: center;
	border-radius: 2.5rem;
}

.cardsSkillsTitle {
	align-self: flex-end;
}

.skillsImg {
	width: 10rem;
	height: auto;
	align-self: center;
}


/* Portfolio -----------------------------------*/
.backgroundPortfolioMain {
   background-color: var(--card);
   display: flex;
	height: 100vh;
   padding: 5rem;
   align-items: center;
}

.portfolioGridMain {
   display: grid;
   height: 50rem;
   width: 100%;
   grid-template-rows: repeat(2, 1fr);
   align-items: center;
   grid-gap: 5rem;
}

.PortfolioButtons {
	font-size: 2.5rem;
	padding: 2.5rem 5rem;
	margin: 5rem;
	border-radius: 5rem;
}

/* Portfolio - Web and Graphic */
.backgroundPortfolio {
   background-color: var(--card);
	display: grid;
   grid-template-rows: 10rem 1fr;
   grid-gap: 2.5rem;
   padding: 5rem;
   min-height: 100vh;
   
}

.webGraphicButtonsBox {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   align-items: flex-start;
   margin: 0 auto;
}

.webGraphicButtons {
   font-size: 2rem;
   display: inline-block;
   width: 30rem;
   padding: 1rem 2rem;
   margin: 2rem;
   border-radius: 5rem;

}

.portfolioGrid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(45rem, 1fr));
	grid-gap: 5rem;
	justify-items: center;
   
}

.cards {
	background: linear-gradient(0deg, var(--card) 5%, var(--wt) 20%, var(--card) 60%);
	max-width: 45rem;
	min-width: 25rem;
	height: auto;
	padding: 2rem;
	border-radius: 5rem;
	box-shadow: 0 6px 12px var(--cardDW), 0 -6px 12px var(--cardLW);
}

.projectLinks {
   color: var(--wt);
	height: 100%;
	display: grid;
	grid-template-rows: repeat(5, auto);
	grid-gap: 1.5rem;
	align-content: space-between;
	justify-items: center;
}

.cards h4 {
	line-height: 1.5;
	background-color: var(--sb);
	width: 100%;
	padding: 2rem;
	margin-top: 0;
	color: var(--wt);
	border-radius: 5rem;
	box-shadow: inset 0 5px 5px var(--pb), inset 0 -4px 5px rgba(204, 233, 255, 0.75);
}

.cards h5 {
   line-height: 1.25;
	padding: 0 2rem;
	margin: 0;
}

.gitHub {
	font-size: 2rem;
	width: 20rem;
	padding: 1rem;
	margin: 2rem;
	border-radius: 5rem;
}

.cardsInfoTech {
	margin-bottom: 0;
	padding: 1rem;
}

.projectImgBox {
	width: 100%;
	height: 25rem;
	padding: 2rem;
	margin-top: 2rem;
	position: relative;
	display: flex;
	align-items: center;
	border-radius: 5rem;
}

.projectImg {
   object-fit: cover;
	object-position: top;
	width: 100%;
	height: 25rem;
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 5rem;
}

/* Contact ------------------------------------*/
.contact {
   background-color: var(--wt);
	overflow-y: scroll;
}

/* Media Query ----------------------------------*/
@media (max-width: 960px) {
   .pagesGrid {
      padding-right: 5rem;
	}
   
   /* Portfolio -----------------------------------*/
   .backgroundPortfolio {
      grid-template-rows: 20rem 1fr;
      
   }
   
   .backgroundPortfolioMain {
      padding: 1rem;
   }
   
   .PortfolioButtons {
      font-size: 2rem;
      margin: 2.5rem;
   }

   /* Portfolio - Web and Graphic */
   .webGraphicButtonsBox {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
   }
   
   .webGraphicButtons {
      font-size: 2rem;
   }
}

@media (max-width: 600px) {
   .pagesGrid {
      padding-right: 2rem;
	}
   
	.navGrid {
      width: 8rem;
	}
   
	/* About */
	.jeuelMobile {
      display: block;
		width: 12rem;
		border: 8px solid var(--rd);
		border-radius: 50%;
		justify-self: flex-end;
	}
   
	/* Portfolio */
   .backgroundPortfolioMain {
      padding: 0.5rem;
   }

   .PortfolioButtons {
      font-size: 1.75rem;
	   padding: 2rem 2.5rem;
      margin: 1rem;
   }

	.backgroundPortfolio {
		width: 85vw;
		padding: 0rem;
	}
	.portfolioGrid {
		width: 100%;
		margin: 0 auto;
		grid-template-columns: 1fr;
		grid-gap: 2rem;
		padding: 1rem 0;
	}

	.cards {
		width: 26rem;
		padding: 0.5rem;
	}

	.cards h4 {
		margin-top: 2rem;
		padding: 1rem;
	}
}
