.navGrid {
	width: 20rem;
	margin: 0;
}

.mainGrid {
	background: linear-gradient(-180deg, var(--pb) 40%, var(--sb) 80%, var(--sb));
	height: 100%;
	padding: 2.5rem;
	display: grid;
	grid-template-rows: 25rem 20rem 1fr;
	grid-gap: 4rem;
	/* justify-content: center; */
	position: fixed;

}

.logoBox {
	width: 15rem;
	position: relative;
}

.g {
	font-family: var(--font1);
	font-size: 21rem;
	color: var(--rd);
	position: absolute;
	bottom: 8.75rem;
	left: 0;
	margin: 0;
}

.well {
	font-family: var(--font1);
	font-size: 8.5rem;
	color: var(--tb);
	position: absolute;
	bottom: 1.5rem;
	left: 0;
	margin: 0;
}

.webDeveloper {
	font-family: var(--font2);
	font-size: 1.98rem;
	color: var(--wt);
	position: absolute;
	bottom: 1rem;
	left: 0;
	margin: 0;
}

.jeuelBox {
	width: 15rem;
	height: 15rem;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	padding: 0;
}

.jeuel {
	width: 15rem;
	height: 15rem;
	border: solid 4px var(--rd);
	border-radius: 50%;
}

.jeuelCircle {
	background: radial-gradient(var(--tb) 40%, var(--pb) 70%, var(--pb) 10%);
	position: absolute;
	top: -1.5rem;
	left: -1.5rem;
	width: 18rem;
	height: 18rem;
	border: 1px solid var(--tb);
	border-radius: 50%;
	z-index: -1;
}

.navLinkBox {
	display: grid;
	align-content: flex-start;
   align-items: center;
}

.navLink {
	font-family: var(--font2);
	font-size: 2rem;
	color: var(--wt);
	width: 15rem;
	margin: 0 auto;
	padding: 3rem 0;
	border: none;
	cursor: pointer;
	border-bottom: 2px solid var(--sd);
}

.navLink:hover {
	color: var(--wt);
}

.navLittleCircles {
	display: inline-block;
	width: 8px;
	height: 8px;
	border: 2px solid var(--tb);
	border-radius: 50%;
	margin: 0 1rem 0.15rem;
}

.activeLittleCircles {
	border: 2px solid var(--wt);
}

.navLinkMenu {
	padding-bottom: 1rem;
	border-bottom: 2px solid var(--sd);
}

.navUnderlineAbout,
.navUnderlinePortfolio,
.navUnderlineContact {
	margin: 0.75rem auto 0;
	border: 1px solid var(--pb);
	opacity: 0;
}

.activeNavLink {
	color: var(--tb);
	transition: ease-in-out 0.5s;
}

.navUnderlineAbout {
	width: 6rem;
}

.navUnderlinePortfolio {
	width: 9rem;
}

.navUnderlineContact {
	width: 8rem;
}

/* Navbar Mobile ------------------------------*/
@media (max-width: 600px) {
	.navGrid {
		width: 8rem;
		margin: 0;
	}

	.mainGrid {
		width: 8rem;
		height: 100%;
		padding: 2rem 1rem;
		grid-gap: 2rem;
		grid-template-rows: 13rem 1fr;
		grid-row-gap: 2rem;
		justify-content: flex-start;
		align-content: flex-start;
		position: fixed;
	}

	.logoBox {
		width: 6rem;
		height: 13rem;
	}

	.g {
		font-size: 9rem;
		bottom: 5.8rem;
	}

	.well {
		font-size: 3.4rem;
		bottom: 2.5rem;
	}

	.webDeveloper {
		font-size: 1.15rem;
		line-height: 1.25;
		bottom: 0rem;
	}

	.jeuelBox {
		display: none;
	}

	.navLinkBox {
		width: 6rem;
	}

	.navLink {
		transform: rotate(270deg);
		width: 11rem;
		height: 11rem;
	}

	.navLittleCircles {
		display: none;
	}

	.navLinkMenu {
		font-size: 1.75rem;
		padding: 0;
	}
}

/* Height */
@media (max-height: 630px) {
	.navGrid {
		width: 20rem;
	}

	.mainGrid {
		width: 20rem;
		padding: 1.5rem;
		grid-template-rows: 16.5rem 1fr;
		grid-gap: 2rem;
	}

	.logoBox {
		justify-self: center;
		width: 10rem;
		height: 16.5rem;
	}

	.g {
		font-size: 14rem;
		bottom: 5.5rem;
	}

	.well {
		font-size: 5.6rem;
		bottom: 0.5rem;
	}

	.webDeveloper {
		font-size: 1.32rem;
		bottom: 0;
	}

	.jeuelBox {
		display: none;
	}

	.navLittleCircles {
		display: none;
	}

	.navLink {
		padding: 0;
	}

	.navLinkMenu {
		padding: 0;
	}
}
