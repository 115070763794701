@import url(https://fonts.googleapis.com/css2?family=Baskervville&family=Jura:wght@700&display=swap);
html {
	background-color: var(--pb);
	font-size: 10px;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: '#0C3559';
	margin: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
	/* --pb: #0c3559;
	--sb: #138ba5; */
	--pb: #0a2a40;
	--sb: #13547f;
	--tb: #26a8ff;
	--rd: #ff1e08;
	--wt: #f2f2f2;

	/* Neumorphic Cards */
	--card: #d9d9d9;
	--cardLW: #ffffff;
	--cardDW: #a6a6a6;

	--cardLB: #cce9ff;

	/* --cardLR: #ffa9a1;  */
	--cardDR: #400702;

	--font1: 'Baskervville', serif;
	--font2: 'Jura', sans-serif;
}

* {
	box-sizing: border-box;
}

h1 {
	font-family: 'Baskervville', serif;
	font-family: var(--font1);
	font-size: 8rem;
	text-align: right;
	color: #0a2a40;
	color: var(--pb);
	margin-bottom: 6rem;
	display: block;
}

h2,
h3,
h4,
h5,
a {
	font-family: 'Jura', sans-serif;
	font-family: var(--font2);
}

h2 {
	font-size: 4rem;
	font-weight: 400;
	text-align: right;
	line-height: 1.75;
	color: #13547f;
	color: var(--sb);
	margin-bottom: 5rem;
	display: block;
}

h3 {
	font-weight: 400;
	font-size: 2.25rem;
	text-align: right;
	line-height: 1.75;
	color: #0a2a40;
	color: var(--pb);
	display: block;
	margin: 1rem 0;
	padding: 0;
}

h4 {
	color: #0a2a40;
	color: var(--pb);
	font-size: 2.25rem;
	font-weight: 600;
	margin: 1rem 0;
}

h5 {
	color: #0a2a40;
	color: var(--pb);
	font-size: 2rem;
	font-weight: 400;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem;
}

a {
	color: #ff1e08;
	color: var(--rd);
	text-decoration: none;
}

a:hover {
	color: #13547f;
	color: var(--sb);
	transition: ease-in-out 0.3s;
}

.App {
	text-align: center;
	height: 100vh;
	display: grid;
	grid-template-columns: 20rem 1fr;
}

/* Media Query ----------------------------------*/
@media (max-width: 960px) {
	h1 {
		font-size: 6rem;
	}

	h2 {
		font-size: 3rem;
	}

	h3 {
		font-size: 2rem;
	}
}

@media (max-width: 600px) {
	h1 {
		font-size: 4rem;
		margin-top: 0;
		margin-bottom: 2rem;
	}

	h2 {
		font-size: 2rem;
		margin-bottom: 2rem;
	}

	h3 {
		font-size: 1.5rem;
		margin-bottom: 2rem;
	}

	h4 {
		font-size: 1.5rem;
		padding: 0 1rem;
	}

	h5 {
		font-size: 1.25rem;
		padding: 0 1rem;
	}

	.App {
		grid-template-columns: 8rem 1fr;
	}
}

.navGrid {
	width: 20rem;
	margin: 0;
}

.mainGrid {
	background: linear-gradient(-180deg, var(--pb) 40%, var(--sb) 80%, var(--sb));
	height: 100%;
	padding: 2.5rem;
	display: grid;
	grid-template-rows: 25rem 20rem 1fr;
	grid-gap: 4rem;
	/* justify-content: center; */
	position: fixed;

}

.logoBox {
	width: 15rem;
	position: relative;
}

.g {
	font-family: var(--font1);
	font-size: 21rem;
	color: var(--rd);
	position: absolute;
	bottom: 8.75rem;
	left: 0;
	margin: 0;
}

.well {
	font-family: var(--font1);
	font-size: 8.5rem;
	color: var(--tb);
	position: absolute;
	bottom: 1.5rem;
	left: 0;
	margin: 0;
}

.webDeveloper {
	font-family: var(--font2);
	font-size: 1.98rem;
	color: var(--wt);
	position: absolute;
	bottom: 1rem;
	left: 0;
	margin: 0;
}

.jeuelBox {
	width: 15rem;
	height: 15rem;
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	margin: 0;
	padding: 0;
}

.jeuel {
	width: 15rem;
	height: 15rem;
	border: solid 4px var(--rd);
	border-radius: 50%;
}

.jeuelCircle {
	background: radial-gradient(var(--tb) 40%, var(--pb) 70%, var(--pb) 10%);
	position: absolute;
	top: -1.5rem;
	left: -1.5rem;
	width: 18rem;
	height: 18rem;
	border: 1px solid var(--tb);
	border-radius: 50%;
	z-index: -1;
}

.navLinkBox {
	display: grid;
	-webkit-align-content: flex-start;
	        align-content: flex-start;
   -webkit-align-items: center;
           align-items: center;
}

.navLink {
	font-family: var(--font2);
	font-size: 2rem;
	color: var(--wt);
	width: 15rem;
	margin: 0 auto;
	padding: 3rem 0;
	border: none;
	cursor: pointer;
	border-bottom: 2px solid var(--sd);
}

.navLink:hover {
	color: var(--wt);
}

.navLittleCircles {
	display: inline-block;
	width: 8px;
	height: 8px;
	border: 2px solid var(--tb);
	border-radius: 50%;
	margin: 0 1rem 0.15rem;
}

.activeLittleCircles {
	border: 2px solid var(--wt);
}

.navLinkMenu {
	padding-bottom: 1rem;
	border-bottom: 2px solid var(--sd);
}

.navUnderlineAbout,
.navUnderlinePortfolio,
.navUnderlineContact {
	margin: 0.75rem auto 0;
	border: 1px solid var(--pb);
	opacity: 0;
}

.activeNavLink {
	color: var(--tb);
	transition: ease-in-out 0.5s;
}

.navUnderlineAbout {
	width: 6rem;
}

.navUnderlinePortfolio {
	width: 9rem;
}

.navUnderlineContact {
	width: 8rem;
}

/* Navbar Mobile ------------------------------*/
@media (max-width: 600px) {
	.navGrid {
		width: 8rem;
		margin: 0;
	}

	.mainGrid {
		width: 8rem;
		height: 100%;
		padding: 2rem 1rem;
		grid-gap: 2rem;
		grid-template-rows: 13rem 1fr;
		grid-row-gap: 2rem;
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
		-webkit-align-content: flex-start;
		        align-content: flex-start;
		position: fixed;
	}

	.logoBox {
		width: 6rem;
		height: 13rem;
	}

	.g {
		font-size: 9rem;
		bottom: 5.8rem;
	}

	.well {
		font-size: 3.4rem;
		bottom: 2.5rem;
	}

	.webDeveloper {
		font-size: 1.15rem;
		line-height: 1.25;
		bottom: 0rem;
	}

	.jeuelBox {
		display: none;
	}

	.navLinkBox {
		width: 6rem;
	}

	.navLink {
		-webkit-transform: rotate(270deg);
		        transform: rotate(270deg);
		width: 11rem;
		height: 11rem;
	}

	.navLittleCircles {
		display: none;
	}

	.navLinkMenu {
		font-size: 1.75rem;
		padding: 0;
	}
}

/* Height */
@media (max-height: 630px) {
	.navGrid {
		width: 20rem;
	}

	.mainGrid {
		width: 20rem;
		padding: 1.5rem;
		grid-template-rows: 16.5rem 1fr;
		grid-gap: 2rem;
	}

	.logoBox {
		justify-self: center;
		width: 10rem;
		height: 16.5rem;
	}

	.g {
		font-size: 14rem;
		bottom: 5.5rem;
	}

	.well {
		font-size: 5.6rem;
		bottom: 0.5rem;
	}

	.webDeveloper {
		font-size: 1.32rem;
		bottom: 0;
	}

	.jeuelBox {
		display: none;
	}

	.navLittleCircles {
		display: none;
	}

	.navLink {
		padding: 0;
	}

	.navLinkMenu {
		padding: 0;
	}
}

/* General ------------------------------------*/
.pagesGrid {
	background-color: var(--wt);
	height: auto;
	padding: 3rem 10% 5rem 5rem;
	display: grid;
	grid-template-rows: repeat(3, auto);
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	-webkit-align-content: flex-start;
	        align-content: flex-start;
	overflow: hidden;
}

/* About  -------------------------------------*/
.about {
	background-color: var(--wt);
	height: auto;
	overflow-y: scroll;
}

.jeuelMobile {
	display: none;
}

/* Skills -----------------------------------*/
.backgroundSkills {
	background-color: var(--card);
	padding: 1rem 5rem 5rem;
}

.skillsTitle {
	text-align: center;
	margin-top: 8rem;
}

.skillsGrid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
	grid-gap: 5rem;
	justify-items: center;
}

.cardsSkills {
	width: 20rem;
	height: 25rem;
	padding: 2rem;
	display: grid;
	justify-items: center;
	-webkit-align-items: center;
	        align-items: center;
	border-radius: 2.5rem;
}

.cardsSkillsTitle {
	-webkit-align-self: flex-end;
	        align-self: flex-end;
}

.skillsImg {
	width: 10rem;
	height: auto;
	-webkit-align-self: center;
	        align-self: center;
}


/* Portfolio -----------------------------------*/
.backgroundPortfolioMain {
   background-color: var(--card);
   display: -webkit-flex;
   display: flex;
	height: 100vh;
   padding: 5rem;
   -webkit-align-items: center;
           align-items: center;
}

.portfolioGridMain {
   display: grid;
   height: 50rem;
   width: 100%;
   grid-template-rows: repeat(2, 1fr);
   -webkit-align-items: center;
           align-items: center;
   grid-gap: 5rem;
}

.PortfolioButtons {
	font-size: 2.5rem;
	padding: 2.5rem 5rem;
	margin: 5rem;
	border-radius: 5rem;
}

/* Portfolio - Web and Graphic */
.backgroundPortfolio {
   background-color: var(--card);
	display: grid;
   grid-template-rows: 10rem 1fr;
   grid-gap: 2.5rem;
   padding: 5rem;
   min-height: 100vh;
   
}

.webGraphicButtonsBox {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   -webkit-align-items: flex-start;
           align-items: flex-start;
   margin: 0 auto;
}

.webGraphicButtons {
   font-size: 2rem;
   display: inline-block;
   width: 30rem;
   padding: 1rem 2rem;
   margin: 2rem;
   border-radius: 5rem;

}

.portfolioGrid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(45rem, 1fr));
	grid-gap: 5rem;
	justify-items: center;
   
}

.cards {
	background: linear-gradient(0deg, var(--card) 5%, var(--wt) 20%, var(--card) 60%);
	max-width: 45rem;
	min-width: 25rem;
	height: auto;
	padding: 2rem;
	border-radius: 5rem;
	box-shadow: 0 6px 12px var(--cardDW), 0 -6px 12px var(--cardLW);
}

.projectLinks {
   color: var(--wt);
	height: 100%;
	display: grid;
	grid-template-rows: repeat(5, auto);
	grid-gap: 1.5rem;
	-webkit-align-content: space-between;
	        align-content: space-between;
	justify-items: center;
}

.cards h4 {
	line-height: 1.5;
	background-color: var(--sb);
	width: 100%;
	padding: 2rem;
	margin-top: 0;
	color: var(--wt);
	border-radius: 5rem;
	box-shadow: inset 0 5px 5px var(--pb), inset 0 -4px 5px rgba(204, 233, 255, 0.75);
}

.cards h5 {
   line-height: 1.25;
	padding: 0 2rem;
	margin: 0;
}

.gitHub {
	font-size: 2rem;
	width: 20rem;
	padding: 1rem;
	margin: 2rem;
	border-radius: 5rem;
}

.cardsInfoTech {
	margin-bottom: 0;
	padding: 1rem;
}

.projectImgBox {
	width: 100%;
	height: 25rem;
	padding: 2rem;
	margin-top: 2rem;
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	border-radius: 5rem;
}

.projectImg {
   object-fit: cover;
	object-position: top;
	width: 100%;
	height: 25rem;
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 5rem;
}

/* Contact ------------------------------------*/
.contact {
   background-color: var(--wt);
	overflow-y: scroll;
}

/* Media Query ----------------------------------*/
@media (max-width: 960px) {
   .pagesGrid {
      padding-right: 5rem;
	}
   
   /* Portfolio -----------------------------------*/
   .backgroundPortfolio {
      grid-template-rows: 20rem 1fr;
      
   }
   
   .backgroundPortfolioMain {
      padding: 1rem;
   }
   
   .PortfolioButtons {
      font-size: 2rem;
      margin: 2.5rem;
   }

   /* Portfolio - Web and Graphic */
   .webGraphicButtonsBox {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
   }
   
   .webGraphicButtons {
      font-size: 2rem;
   }
}

@media (max-width: 600px) {
   .pagesGrid {
      padding-right: 2rem;
	}
   
	.navGrid {
      width: 8rem;
	}
   
	/* About */
	.jeuelMobile {
      display: block;
		width: 12rem;
		border: 8px solid var(--rd);
		border-radius: 50%;
		justify-self: flex-end;
	}
   
	/* Portfolio */
   .backgroundPortfolioMain {
      padding: 0.5rem;
   }

   .PortfolioButtons {
      font-size: 1.75rem;
	   padding: 2rem 2.5rem;
      margin: 1rem;
   }

	.backgroundPortfolio {
		width: 85vw;
		padding: 0rem;
	}
	.portfolioGrid {
		width: 100%;
		margin: 0 auto;
		grid-template-columns: 1fr;
		grid-gap: 2rem;
		padding: 1rem 0;
	}

	.cards {
		width: 26rem;
		padding: 0.5rem;
	}

	.cards h4 {
		margin-top: 2rem;
		padding: 1rem;
	}
}

.portfolioGraphicGrid {
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(50rem, 1fr));
   grid-template-rows: 1fr;
   grid-gap: 10rem;
   -webkit-align-items: center;
           align-items: center;
   margin: 0 auto;
}

.graphicImages {
   max-width: 100rem;
   max-height: 100rem;
}

.graphicImagesLogos {
   max-width: 75rem;
   max-height: 50rem;
}
